<template>
  <div class="bg-black FoundersGrotesk" id="footer">
    <b-row>
      <b-col cols="6" md="6" class="first-col">
        <div v-for="link in footerColumn1" :key="link.text">
          <a :href="link.url" target="_blank" class="lg-link">{{
            link.text
          }}</a>
        </div>
      </b-col>
      <b-col cols="6" md="5" class="second-col text-right">
        <div v-for="link in footerColumn2" :key="link.text">
          <a :href="link.url" target="_blank" class="md-link">{{
            link.text
          }}</a>
        </div>
      </b-col>
      <b-col cols="12" md="1" class="third-col text-right p-md-0">
        <div v-for="link in footerColumn3" :key="link.text">
          <a :href="link.url" target="_blank" class="md-link">{{
            link.text
          }}</a>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      footerColumn1: [{ text: "Ode Dundas", url: "#" }],
      footerColumn2: [
        {
          text: "1417 DUNDAS STREET W.",
          url: "https://goo.gl/maps/qU6sGrNxGRtWjyf19",
        },
        { text: "TIFFANY@ODETORONTO.CA", url: "mailto:tiffany@odetoronto.ca" },
        { text: "TERMS & CONDITIONS", url: "/terms" },
        { text: "PRIVACY POLICY", url: "/privacy" },
      ],
      footerColumn3: [
        {
          text: "INSTAGRAM",
          url: "https://www.instagram.com/ode_toronto/?utm_medium=copy_link",
        },
        { text: "FACEBOOK", url: "https://www.facebook.com/OdeToronto" },
        { text: "TIKTOK", url: "https://www.tiktok.com/@ode_toronto" },
        {
          text: "SPOTIFY",
          url: "https://open.spotify.com/playlist/1JRl1fTUvdZcJDKbKYIH1o?si=cXDn3CDTRNqJ2WWAGAlvBw",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";

#footer {
  padding: 30px;

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $m-white;
    text-decoration: none;
  }

  .first-col {
    @media screen and (max-width: 768px) {
      padding-bottom: 25px;
    }
  }

  .lg-link {
    font-size: 25px;
  }

  .md-link {
    font-size: 14px;

    @media screen and (max-width: 900px) {
      line-height: 5px;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
}
</style>
